import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import * as XLSX from "xlsx";
import DatePicker from "react-multi-date-picker";
import { DateObject } from "react-multi-date-picker";
import { FaCloudDownloadAlt } from "react-icons/fa";

const $ = require("jquery");
$.Datatable = require("datatables.net");

const PenaltyReport = () => {
  const [user, setUser] = useState();

  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  const baseUrl =
    nodeMode === "development" ? beckendLocalApiUrl : beckendLiveApiUrl;

  let [limit, setLimit] = useState(10);
  const setpageLimit = (event) => setLimit(event.target.value);

  const [pageNumber, setPageNumber] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);

  const [startDate, setStartDate] = useState(
    new DateObject().subtract(4, "days")
  );
  const [endDate, setEndDate] = useState(new DateObject().add(4, "days"));

  const handlePageClick = async (data) => {
    let currentPage = data.selected + 1;
    setPageNumber(currentPage);
  };
  // penaltyreports/all?page=${pageNumber}&_limit=${limit}&FROM_DATE=${values[0]}&TO_DATE=${values[1]}`
  const profle = () => {
    const access_token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${access_token}` };
    axios
      .get(
        `${baseUrl}txn/penaltyreports/all?page=${pageNumber}&_limit=${limit}&FROM_DATE=${startDate}&TO_DATE=${endDate}`,
        { headers }
      )
      .then((res) => {
        setUser(res.data.datefind);
        // console.log( "pending data",res.data);

        setNumberOfPages(res.data.totalPages);
      });
  };

  const dateFormat = (e) => {
    const date = new Date(e);
    return date.toLocaleString("default", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    });
  };

  useEffect(() => {
    profle();
  }, [pageNumber, limit, startDate, endDate]);

  if (!user) return null;

  const createDownloadData = () => handleExport();
  const handleExport = () => {
    let table1 = [
      {
        A: "Id",
        B: "UserName",
        C: "PhoneNumber",
        D: "Penalty Amount",
        E: "Penalty By",
        // F:"Penalty by (ID)"
      },
    ];

    user.forEach((row) => {
      table1.push({
        A: row._id,
        B: row.userId ? row.userId.name : "",
        C: row.userId ? row.userId.phone : "",
        D: row.amount,
        E: row.action_byName ? row.action_byName : "N/A",
      });
    });

    const ws = XLSX.utils.json_to_sheet(table1, { skipHeader: true });
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
    XLSX.writeFile(wb, "PenaltyReports.xlsx");
  };

  return (
    <div className="row ">
      <div className="col-12 grid-margin">
        <div
          className="card"
          // style={{ borderRadius: "10px", backgroundColor: "#1f2937" }}
        >
          <div
            className="card-body"
            // style={{ backgroundColor: "#0d1b2a", borderRadius: "10px" }}
          >
            <h4
              className="card-title"
              // style={{ color: "white" }}
            >
              Penalty Reports
            </h4>
            <button
              onClick={() => {
                createDownloadData();
              }}
              className="btn btn-primary download-button"
              onMouseEnter={(e) => {
                e.target.style.backgroundColor = "#0056b3";
                e.target.style.transform = "scale(1.05)";
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = "#007bff";
                e.target.style.transform = "scale(1)";
              }}
            >
              <FaCloudDownloadAlt style={{ fontSize: "24px" }} />
            </button>

            <div className="d-flex justify-content-between align-items-center mb-3 mt-3">
              {/* Start Date Picker */}
              <div style={{ marginRight: "10px" }}>
                <label
                  style={{
                    color: "#fff",
                    marginRight: "10px",
                    fontSize: "18px",
                  }}
                >
                  Start Date:
                </label>
                <DatePicker
                  value={startDate}
                  onChange={setStartDate}
                  style={{
                    backgroundColor: "#f8f9fb",
                    color: "#black",
                    borderRadius: "5px",
                    padding: "5px",
                    minWidth: "120px",
                    height: "40px",
                  }}
                />
              </div>

              {/* End Date Picker */}
              <div style={{ marginRight: "10px" }}>
                <label
                  style={{
                    color: "#fff",
                    marginRight: "10px",
                    fontSize: "18px",
                  }}
                >
                  End Date:
                </label>
                <DatePicker
                  value={endDate}
                  onChange={setEndDate}
                  style={{
                    backgroundColor: "#f8f9fb",
                    color: "#black",
                    borderRadius: "5px",
                    padding: "5px",
                    minWidth: "120px",
                    height: "40px",
                  }}
                />
              </div>

              <select
                // className="form-control col-sm-1 "
                id="pagelimit"
                name="pagelimit"
                onChange={setpageLimit}
                style={{
                  borderRadius: "5px",
                  backgroundColor: "#f8f9fb",
                  color: "black",
                  minWidth: "120px",
                  height: "40px",
                }}
              >
                <option value="10">Set limit</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="500">500</option>
              </select>
            </div>
            {user.length > 0 ? (
              <>
                <div className="table-responsive mt-3">
                  <table
                    className="table text-light table-hover"
                    // style={{ borderRadius: "10px", color: "#fff" }}
                  >
                    <thead>
                      <tr
                      // style={{ backgroundColor: "#14213d" }}
                      >
                        <th>#</th>
                        <th>Id</th>
                        <th>Mob.No</th>
                        <th>User</th>
                        <th>Amount</th>
                        <th>Status</th>
                        <th>Date</th>
                        <th>Penalty By</th>
                      </tr>
                    </thead>
                    <tbody>
                      {user.map((data, key) => (
                        <tr
                          key={data._id}
                          style={{ borderBottom: "1px solid #e5e7eb" }}
                        >
                          <td>{key + 1}</td>
                          <td>{data._id}</td>
                          <td>{data.userId ? data.userId.phone : ""}</td>
                          <td>
                            {data.userId && (
                              <Link
                                className="nameButton badge"
                                to={`/user/view_user/${data.userId._id}`}
                                // style={{
                                //   backgroundColor: "#e5e7eb",
                                //   color: "#1f2937",
                                //   fontWeight: "bold",
                                //   borderRadius: "20px",
                                //   padding: "5px 15px",
                                //   textTransform: "capitalize",
                                //   fontSize: "14px",
                                // }}
                              >
                                {data.userId.name}
                              </Link>
                            )}
                          </td>
                          <td
                            style={{
                              color:
                                data.status === "Penalty by Admin"
                                  ? "#ff0000"
                                  : "#00ff00",
                              fontWeight: "bold",
                            }}
                          >
                            - ₹{data.amount}
                          </td>
                          <td style={{ color: "#ff0000", fontWeight: "bold" }}>
                            {data.status}
                          </td>
                          <td>{dateFormat(data.createdAt)}</td>
                          <td
                            style={{
                              color: data.actionBy ? "#ff0000" : "yellow",
                            }}
                          >
                            {data.actionBy ? data.actionBy.name : "N/A"}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                {numberOfPages > 1 && (
                  <div className="mt-4">
                    <ReactPaginate
                      previousLabel={"Previous"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      pageCount={numberOfPages}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={3}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination justify-content-center"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                      pageLinkStyle={{ color: "#fff" }}
                      activeLinkStyle={{
                        backgroundColor: "#00ff00",
                        borderColor: "#00ff00",
                      }}
                    />
                  </div>
                )}
              </>
            ) : (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{
                  backgroundColor: "#14213d",
                  padding: "20px",
                  borderRadius: "10px",
                  color: "#fff",
                  marginTop: "15px",
                }}
              >
                <h4>No Data Found</h4>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PenaltyReport;
