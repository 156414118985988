import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation, Link } from "react-router-dom";
import { FcApprove } from "react-icons/fc";
import { GiCancel } from "react-icons/gi";
import { GrFormView } from "react-icons/gr";
import UserCard from "../../shared/UserCard";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
const $ = require("jquery");
$.Datatable = require("datatables.net");

export default function View() {
  const location = useLocation();
  const path = location.pathname.split("/")[3];
  const { id } = useParams();
  const [user, setUser] = useState();
  const [item, setVerify] = useState();
  const [challenge, setchallenge] = useState();
  const [combinedData, setCombinedData] = useState();
  const [txn, setTxn] = useState();
  const [bonusHis, setBonusHis] = useState();
  const [penaltyHis, setPenaltyHis] = useState();
  const [txnwith, setTxnwith] = useState();
  const [referral, setReferral] = useState();
  const [kyc, setKyc] = useState();
  const [showHoldCredited, setShowHoldCredited] = useState(false);
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }

  let [mismatchValue, setmismatchValue] = useState(0);
  let [HoldBalance, setHoldBalance] = useState(0);

  const getUser = () => {
    let access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    axios
      .get(baseUrl + `get_user/${path}`, { headers })
      .then((res) => {
        console.log("user data single-------", res.data);
        setUser(res.data);
        Allrefer(res.data.referralCode);
        setmismatchValue(res.data.holdBalance);
        setHoldBalance(res.data.holdBalance);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const [referCount, setRefercount] = useState([]);
  const Allrefer = async (id) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    await axios
      .get(baseUrl + `referral/code/${id}`, { headers })
      .then((res) => {
        setRefercount(res.data);
        console.log(res.data);
      });
  };

  const Allchallenge = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .get(baseUrl + `get_challange/user/${path}`, { headers })
      .then((res) => {
        setCombinedData(undefined); //Setting state to undefined is a way to indicate that there’s no data available for those state variables. This helps avoid displaying stale or irrelevant information to the use
        setTxn(undefined);
        setTxnwith(undefined);
        setReferral(undefined);
        setKyc(undefined);
        setPenaltyHis(undefined);
        setBonusHis(undefined);
        const challengeFilter = res.data.filter(
          (item) => item.status !== "Hold Credited"
        );
        if (res.data.message) {
          console.log(res);
          setchallenge(undefined);
          $("table").dataTable();
          imageViewer();
        } else {
          console.log(res);
          setchallenge(challengeFilter);
          $("table").dataTable();
          console.log("game datata is ", challengeFilter);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const AllHistory = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .get(baseUrl + `get_history/user/${path}`, { headers })
      .then((res) => {
        setTxn(undefined);
        setTxnwith(undefined);
        setReferral(undefined);
        setKyc(undefined);
        setchallenge(undefined);
        setPenaltyHis(undefined);
        setBonusHis(undefined);

        // const completedHistory = res.data.filter(
        //   (item) => item.status !== "none" && item.status!=="Hold Credited"
        // );
        // const sortedData = completedHistory.sort(
        //   (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        // );

        // console.log(sortedData);

        const filteredData = !showHoldCredited
          ? res.data.filter(
              (item) =>
                item.status !== "none" && item.status !== "Hold Credited"
            )
          : res.data.filter((item) => item.status !== "none");

        if (res.data.message) {
          console.log(res);
          setCombinedData(undefined);
          $("table").dataTable();
        } else {
          console.log(res.data);
          // setCombinedData(sortedData);
          // setCombinedData(completedHistory);
          setCombinedData(filteredData);
          $("table").dataTable();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const handleCheckboxChange = () => {
    // console.log("yyyyyyyyyyyyyyyyyyyyyy");
    setShowHoldCredited(!showHoldCredited);
  };

  const transactionHis = () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .get(baseUrl + `txn_history/user/${path}`, { headers })
      .then((res) => {
        setCombinedData(undefined);
        setchallenge(undefined);
        setReferral(undefined);
        setKyc(undefined);
        setTxnwith(undefined);
        setBonusHis(undefined);
        setPenaltyHis(undefined);
        if (res.data.message) {
          console.log(res);
          setTxn(undefined);
          $("table").dataTable();
          imageViewer();
        } else {
          console.log(res);
          setTxn(res.data);
          console.log("depositdata ", res.data);
          $("table").dataTable();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const BonusHis = () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .get(baseUrl + `bonus_history/user/${path}`, { headers })
      .then((res) => {
        setCombinedData(undefined);
        setchallenge(undefined);
        setReferral(undefined);
        setKyc(undefined);
        setTxnwith(undefined);
        setTxn(undefined);
        setPenaltyHis(undefined);
        if (res.data.message) {
          console.log(res);
          setBonusHis(undefined);
          $("table").dataTable();
          imageViewer();
        } else {
          console.log(res);
          setBonusHis(res.data);
          console.log("bonus data ", res.data);
          $("table").dataTable();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const PenaltyHis = () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .get(baseUrl + `penalty_history/user/${path}`, { headers })
      .then((res) => {
        setCombinedData(undefined);
        setchallenge(undefined);
        setReferral(undefined);
        setKyc(undefined);
        setTxnwith(undefined);
        setTxn(undefined);
        setBonusHis(undefined);
        if (res.data.message) {
          console.log(res);
          setPenaltyHis(undefined);
          $("table").dataTable();
          imageViewer();
        } else {
          console.log(res);
          setPenaltyHis(res.data);
          console.log("penalty data ", res.data);
          $("table").dataTable();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const withdrawalHis = () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .get(baseUrl + `txnwith_history/user/${path}`, { headers })
      .then((res) => {
        setCombinedData(undefined);
        setTxn(undefined);
        setchallenge(undefined);
        setReferral(undefined);
        setKyc(undefined);
        setPenaltyHis(undefined);
        setBonusHis(undefined);
        if (res.data.message) {
          setTxnwith(undefined);
          $("table").dataTable();
          imageViewer();
        } else {
          setTxnwith(res.data);
          console.log("hello srk", res.data);
          $("table").dataTable();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const dateFormat = (e) => {
    const date = new Date(e);
    return date.toLocaleString("default", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    });
  };

  const referralHis = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    console.log("at front end :- ", user.referralCode);
    await axios
      .get(baseUrl + `referral/code/win/${user.referralCode}`, { headers })
      .then((res) => {
        setCombinedData(undefined);
        setchallenge(undefined);
        setTxn(undefined);
        setTxnwith(undefined);
        setKyc(undefined);
        setPenaltyHis(undefined);
        setBonusHis(undefined);
        if (res.data.message) {
          setReferral(undefined);
          console.log(res.data);
          $("table").dataTable();
          imageViewer();
        } else {
          setReferral(res.data);
          // console.log("refferal data===========", res.data);
          $("table").dataTable();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getKyc = () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios.get(baseUrl + `admin/user/kyc/${path}`, { headers }).then((res) => {
      setCombinedData(undefined);
      setTxn(undefined);
      setTxnwith(undefined);
      setReferral(undefined);
      setchallenge(undefined);
      setPenaltyHis(undefined);
      setBonusHis(undefined);
      if (res.data.message) {
        setKyc(undefined);
        console.log(res.data);
        $("table").dataTable();
        imageViewer();
      } else {
        setKyc(res.data);
        console.log("kyc data of user=========", res.data);
        // console.log("base url of kyc with ====",baseUrl + `${item.back}`);

        $("table").dataTable();
        imageViewer();
      }
    });
  };

  function imageViewer() {
    let imgs = document.getElementsByClassName("img"),
      out = document.getElementsByClassName("img-out")[0];
    for (let i = 0; i < imgs.length; i++) {
      if (!imgs[i].classList.contains("el")) {
        imgs[i].classList.add("el");
        imgs[i].addEventListener("click", lightImage);
        function lightImage() {
          let container = document.getElementsByClassName("img-panel")[i];
          container.classList.toggle("img-panel__selct");
        }

        imgs[i].addEventListener("click", openImage);
        function openImage() {
          let imgElement = document.createElement("img"),
            imgWrapper = document.createElement("div"),
            imgClose = document.createElement("div"),
            container = document.getElementsByClassName("img-panel")[i];
          container.classList.add("img-panel__selct");
          imgElement.setAttribute("class", "image__selected");
          imgElement.src = imgs[i].src;
          imgWrapper.setAttribute("class", "img-wrapper");
          imgClose.setAttribute("class", "img-close");
          imgWrapper.appendChild(imgElement);
          imgWrapper.appendChild(imgClose);

          setTimeout(function () {
            imgWrapper.classList.add("img-wrapper__initial");
            imgElement.classList.add("img-selected-initial");
          }, 50);

          out.appendChild(imgWrapper);
          imgClose.addEventListener("click", function () {
            container.classList.remove("img-panel__selct");
            out.removeChild(imgWrapper);
          });
        }
      }
    }
  }
  const update = (Id) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .patch(
        baseUrl + `aadharcard/${Id}`,
        { verified: "verified" },
        { headers }
      )
      .then((res) => {
        getUser();
        getKyc();
      });
  };

  const updateMismatch = (Id) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .patch(baseUrl + `user/missmatch/clear/${Id}`, { headers })
      .then((res) => {
        getUser();
        console.log(res);
      });
  };

  const updateHold = (Id) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios.patch(baseUrl + `user/Hold/clear/${Id}`, { headers }).then((res) => {
      getUser();
      console.log(res);
    });
  };

  const checkfailedpayout = (txn_id, referenceId) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .post(
        baseUrl + `payout/response/api`,
        { txn_id, referenceId },
        { headers }
      )
      .then((res) => {
        const icon = res.data.status === "SUCCESS" ? "success" : "danger";
        const title =
          res.data.status === "SUCCESS"
            ? "Withdraw successfully"
            : "Transaction Proccessing or Failed";
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
          window.location.reload();
        }
      });
  };

  const deletedata = (Id) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .patch(baseUrl + `aadharcard/${Id}`, { verified: "reject" }, { headers })
      .then((res) => {
        getUser();
        getKyc();
      });
  };
  useEffect(() => {
    getUser();
    Allchallenge();
    AllHistory();
    getKyc();
  }, [id, showHoldCredited]);
  const userCards = [
    {
      cardName: "User Kyc",
      data: user?.verified,
      myColor:
        user?.verified === "verified"
          ? "#2c6e49"
          : user?.verified === "unverified"
          ? "#c94756"
          : "#c9a747",
      dataColor:
        user?.verified === "verified"
          ? "#2c6e49"
          : user?.verified === "unverified"
          ? "#c94756"
          : "#c9a747",
      myIcon: "mdi mdi-checkbox-multiple-marked-outline",
    },
    {
      cardName: "User Mobile",
      data: user?.phone,
      myColor: "#432818",
      myIcon: "mdi mdi-phone",
    },
    {
      cardName: "User Wallet Balance",
      data: user?.walletBalance,
      myColor: "#003049",
      myIcon: "mdi mdi-bank",
    },
    {
      cardName: "Withdrawal Balance",
      data: user?.withdrawAmount,
      myColor: "#32a89d",
      myIcon: "mdi mdi-square-inc-cash",
    },
    {
      cardName: "User Deposit",
      data: user?.totalDeposit,
      myColor: "#3240a8",
      myIcon: "mdi mdi-square-inc-cash",
    },
    {
      cardName: "User Bonus",
      data: user?.totalBonus,
      myColor: "#32a869",
      myIcon: "mdi mdi-currency-inr",
    },
    {
      cardName: "User Penalty",
      data: user?.totalPenalty,
      myColor: "#a83e32",
      myIcon: "mdi mdi-currency-eur",
    },
    {
      cardName: "User Total Withdraw",
      data: user?.totalWithdrawal,
      myColor: "#a89132",
      myIcon: "mdi mdi-square-inc-cash",
    },
    {
      cardName: "User wonAmount",
      data: user?.wonAmount,
      myColor: "#273d63",
      myIcon: "mdi mdi-currency-inr",
    },
    {
      cardName: "User loseAmount",
      data: user?.loseAmount,
      myColor: "#476a91",
      myIcon: "mdi mdi-currency-btc",
    },
    {
      cardName: "User HoldBalance",
      data: HoldBalance,
      myColor: "#a88732",
      myIcon: "mdi mdi-currency-cny",
    },
    {
      cardName: "Mismatch Wallet Balance",
      data: mismatchValue,
      myColor: "#a86032",
      myIcon: "mdi mdi-currency-inr",
    },
    {
      cardName: "Withdrawal Hold Balance",
      data: user?.withdrawHoldBalance,
      // note:"user can make request if balance is 0",
      myColor: "#99a832",
      myIcon: "mdi mdi-currency-gbp",
    },
    {
      cardName: "User Referral Balance",
      data: user?.referralWallet,
      myColor: "#3246a8",
      myIcon: "mdi mdi-currency-usd",
    },
    {
      cardName: "User Referral Earning",
      data: user?.referralEarning,
      myColor: "#7332a8",
      myIcon: "mdi mdi-database-plus",
    },
    {
      cardName: "User Referral Code",
      data: user?.referralCode,
      myColor: "#5e63e6",
      myIcon: "mdi mdi-numeric",
    },
    {
      cardName: "User Referral By",
      data: user?.referral ?? "None",
      myColor: "#e6775e",
      myIcon: "mdi mdi-account-multiple-outline",
    },
    {
      cardName: "User Name At Bank",
      data: user?.holderName ?? "Bank Details Not Available",
      myColor: "#91476a",
      myIcon: "mdi mdi-bank",
    },
    {
      cardName: "User Bank IFSC Code",
      data: user?.ifscCode ?? "Bank Details Not Available",
      myColor: "#624791",
      myIcon: "mdi mdi-numeric-0-box-multiple-outline",
    },
    {
      cardName: "User Account Number",
      data: user?.accountNumber ?? "Bank Details Not Available",
      myColor: "#3e51cf",
      myIcon: "mdi mdi-numeric",
    },
    {
      cardName: "User UPI ID",
      data: user?.upiId ?? "Not Available",
      myColor: "#7e9147",
      myIcon: "mdi mdi-forum",
    },
    {
      cardName: "User CreatedAt",
      data: dateFormat(user?.createdAt),
      myColor: "#479161",
      myIcon: "mdi mdi-contrast",
    },
    {
      cardName: "User UpdatedAt",
      data: dateFormat(user?.updatedAt),
      myColor: "#47917c",
      myIcon: "mdi mdi-account-edit",
    },
  ];

  return (
    <>
      {Boolean(user) && (
        <>
          <div>
            <h4 className="mt-5 text-dark">User Details - {user?.name}</h4>
            <div className="row mt-3">
              {userCards.map((card, index) => (
                <UserCard
                  key={index}
                  cardName={card.cardName}
                  data={card.data}
                  // note={card.note}
                  myColor={card.myColor}
                  dataColor={card.dataColor ?? card.myColor}
                  myIcon={card.myIcon}
                  forwardUrl={card.forwardUrl ?? ""}
                  isWidthSort={true}
                />
              ))}
            </div>

            <div className="col-12 d-flex justify-content-center mt-5 mb-4">
              <button
                onClick={() => updateMismatch(user._id)}
                className="btn btn-success mx-2 text-light"
                style={{ borderRadius: "8px", minWidth: "150px" }}
              >
                Clear Mismatch ₹({mismatchValue})
              </button>
              <button
                onClick={() => updateHold(user._id)}
                className="btn btn-danger mx-2 text-light"
                style={{ borderRadius: "8px", minWidth: "150px" }}
              >
                Clear Hold ₹({HoldBalance})
              </button>
            </div>
          </div>

          <div className="row">
            <div className="col-12 grid-margin">
              <div className="card" style={{ border: "none" }}>
                <div className="card-body bg-light">
                  <div
                    style={{
                      display: "flex",
                      // flexWrap: "wrap",
                      gap: "8px",
                      marginBottom: "20px",
                      overflow: "auto",
                      maxWidth: "100%",
                      paddingBottom: "20px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <button
                      style={{
                        backgroundColor: combinedData ? "#565f6a" : "#202938",
                        color: "#f5eded",
                        height: "30px",
                        borderRadius: "8px",
                        border: "none",
                        fontSize: "15px",
                        cursor: "pointer",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                        transition: "transform 0.2s",
                      }}
                      onClick={AllHistory}
                      onMouseOver={(e) =>
                        (e.currentTarget.style.transform = "scale(1.05)")
                      }
                      onMouseOut={(e) =>
                        (e.currentTarget.style.transform = "scale(1)")
                      }
                    >
                      All History
                    </button>
                    <button
                      style={{
                        backgroundColor: challenge ? "#565f6a" : "#202938",
                        color: "#f5eded",
                        height: "30px",
                        borderRadius: "8px",
                        fontSize: "15px",
                        border: "none",
                        cursor: "pointer",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                        transition: "transform 0.2s",
                      }}
                      onClick={Allchallenge}
                    >
                      Game History
                    </button>
                    <button
                      style={{
                        backgroundColor: txn ? "#565f6a" : "#202938",
                        color: "#f5eded",
                        height: "30px",
                        borderRadius: "8px",
                        fontSize: "15px",
                        border: "none",
                        cursor: "pointer",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                      }}
                      onClick={transactionHis}
                    >
                      Deposit History
                    </button>
                    <button
                      style={{
                        backgroundColor: bonusHis ? "#565f6a" : "#202938",
                        color: "#f5eded",
                        height: "30px",
                        borderRadius: "8px",
                        fontSize: "15px",
                        border: "none",
                        cursor: "pointer",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                      }}
                      onClick={BonusHis}
                    >
                      Bonus History
                    </button>
                    <button
                      style={{
                        backgroundColor: penaltyHis ? "#565f6a" : "#202938",
                        color: "#f5eded",
                        height: "30px",
                        borderRadius: "8px",
                        fontSize: "15px",
                        border: "none",
                        cursor: "pointer",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                      }}
                      onClick={PenaltyHis}
                    >
                      Penalty History
                    </button>
                    <button
                      style={{
                        backgroundColor: txnwith ? "#565f6a" : "#202938",
                        color: "#f5eded",
                        height: "30px",
                        borderRadius: "8px",
                        fontSize: "15px",
                        border: "none",
                        cursor: "pointer",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                      }}
                      onClick={withdrawalHis}
                    >
                      Withdrawal History
                    </button>
                    <button
                      style={{
                        backgroundColor: referral ? "#565f6a" : "#202938",
                        color: "#f5eded",
                        height: "30px",
                        borderRadius: "8px",
                        border: "none",
                        padding: "0 5px",
                        fontSize: "15px",
                        cursor: "pointer",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                      }}
                      onClick={referralHis}
                    >
                      Referral History
                    </button>
                    <button
                      style={{
                        backgroundColor: kyc ? "#565f6a" : "#202938",
                        color: "#f5eded",
                        height: "30px",
                        padding: "0 20px",
                        border: "none",
                        borderRadius: "8px",
                        fontSize: "13px",
                      }}
                      onClick={getKyc}
                    >
                      KYC
                    </button>
                  </div>
                  {/*----------- check box for Hold Entries --------------*/}
                  <div style={{ marginBottom: "10px" }}>
                    <label>
                      <input
                        type="checkbox"
                        checked={showHoldCredited}
                        onChange={handleCheckboxChange}
                      />
                      Show Hold Credited Entries
                    </label>
                  </div>
                  {/* ------------------------------------------------------------------- */}
                  {Boolean(combinedData) && (
                    <div
                      className="table-responsive"
                      style={{ marginTop: "20px" }}
                    >
                      <table
                        className="table"
                        style={{
                          backgroundColor: "#cedcf5",
                          borderRadius: "10px",
                          overflow: "hidden",
                          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                        }}
                      >
                        <thead
                          style={{
                            backgroundColor: "#202938",
                            color: "#f2f4f5",
                            fontWeight: "bold",
                          }}
                        >
                          <tr>
                            <th>#</th>
                            <th>ID</th>
                            <th>Creator</th>
                            <th>Accepter</th>
                            <th>Amount</th>
                            <th>Win/Lose Amt</th>
                            <th>Closing Balance</th>
                            <th>Status</th>
                            <th>Type</th>
                            <th>Winner</th>
                            <th>Date</th>
                            <th>Action</th>
                            <th>View</th>
                          </tr>
                        </thead>

                        <tbody>
                          {combinedData.map((item, key) =>
                            item.winner?._id !== user?._id &&
                            item.status === "Win Credited" ? (
                              ""
                            ) : (
                              <tr
                                key={key}
                                style={{
                                  backgroundColor:
                                    key % 2 === 0 ? "#b4d1ed" : "#f1f1f1",
                                }}
                              >
                                <td>{key + 1}</td>
                                <td>{item._id}</td>
                                <td>
                                  {item.createdBy ? (
                                    <Link
                                      className="btn"
                                      to={`/user/view_user/${item.createdBy._id}`}
                                      style={{
                                        backgroundColor: "#e5e7eb",
                                        color: "#1f2937",
                                        fontWeight: "bold",
                                        borderRadius: "20px",
                                        padding: "5px 15px",
                                        textTransform: "capitalize",
                                        fontSize: "14px",
                                      }}
                                    >
                                      {item.createdBy?.name}
                                    </Link>
                                  ) : (
                                    "None"
                                  )}
                                </td>
                                <td>
                                  {item.acceptedBy ? (
                                    <Link
                                      className="btn"
                                      to={`/user/view_user/${item.acceptedBy._id}`}
                                      style={{
                                        backgroundColor: "#e5e7eb",
                                        color: "#1f2937",
                                        fontWeight: "bold",
                                        borderRadius: "20px",
                                        padding: "5px 15px",
                                        textTransform: "capitalize",
                                        fontSize: "14px",
                                      }}
                                    >
                                      {item.acceptedBy?.name}
                                    </Link>
                                  ) : (
                                    "None"
                                  )}
                                </td>
                                <td
                                  style={{
                                    color:
                                      item.status === "Penalty by Admin" ||
                                      item.status === "FAILED"
                                        ? "#f73b3b"
                                        : item.status === "Pending" ||
                                          item.status === "reject"
                                        ? "#f79605"
                                        : item.status === "completed" ||
                                          item.status === "cancelled"
                                        ? "#a1aec4"
                                        : "#3ea113",
                                    fontWeight: "bold",
                                  }}
                                >
                                  ₹{item.gameAmount || item.amount || 0}
                                </td>

                                <td
                                  style={{
                                    color:
                                      item.winner &&
                                      user._id === item.winner._id &&
                                      item?.winAmount
                                        ? "#3ea113"
                                        : item?.gameAmount &&
                                          item.status === "completed"
                                        ? "#f73b3b"
                                        : item.status === "Hold Credited"
                                        ? "#3ea113"
                                        : item.status === "cancelled"
                                        ? "#f73b3b"
                                        : "#a1aec4",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {item?.winner &&
                                  user._id === item.winner._id &&
                                  item.winAmount
                                    ? `+ ₹${item.winAmount}`
                                    : item?.gameAmount &&
                                      item.status === "completed"
                                    ? `- ₹${item.gameAmount}`
                                    : item.status === "Hold Credited"
                                    ? `+ ₹${item.gameAmount}`
                                    : item.status === "cancelled"
                                    ? `- ₹${item.gameAmount}`
                                    : ""}
                                </td>

                                <td>
                                  {(() => {
                                    if (item.closingBalance) {
                                      return `₹${item.closingBalance}`;
                                    }
                                    if (
                                      item.status === "cancelled" ||
                                      item.status === "Hold Credited" ||
                                      item.status === "completed" ||
                                      item.status === "Win Credited"
                                    ) {
                                      return user._id === item.acceptedBy?._id
                                        ? `₹${
                                            item.Acceptor_closingbalance ?? 0
                                          }`
                                        : `₹${
                                            item.Creator_closingbalance ?? 0
                                          }`;
                                    }
                                    return null;
                                  })()}
                                </td>
                                <td
                                  style={{
                                    color:
                                      item.winAmount &&
                                      user._id === item.winner._id &&
                                      item.status === "completed"
                                        ? "#3ea113"
                                        : item.gameAmount &&
                                          item.status === "completed"
                                        ? "#f73b3b"
                                        : item.status === "cancelled" ||
                                          item.status === "Penalty by Admin" ||
                                          item.status === "FAILED"
                                        ? "#f73b3b"
                                        : item.status === "Pending" ||
                                          item.status === "reject"
                                        ? "#f79605"
                                        : "#3ea113",
                                    fontWeight: "bold",
                                    fontSize: "13px",
                                  }}
                                >
                                  {item.status === "completed"
                                    ? item.winner?._id === user?._id
                                      ? "completed"
                                      : "Game Lose"
                                    : item.status === "Refunded" &&
                                      item.reqType === "withdraw"
                                    ? "Withdraw Credited Back to Wallet"
                                    : item.status
                                    ? item.status
                                    : `Referral earned From ${item.earnedFrom.name}`}
                                </td>

                                <td
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {item.reqType ??
                                    (item.gameType
                                      ? decodeURIComponent(item.gameType)
                                      : "Referral")}
                                  {/* {item.reqType
                                    ? item.reqType ?? "Referral"
                                    : decodeURIComponent(item.gameType)
                                    }
                                     */}
                                </td>

                                <td
                                  style={{
                                    color: !item.winner ? "#a1aec4" : "#3ea113",
                                    fontWeight: "bold",
                                    fontSize: "13px",
                                  }}
                                >
                                  {item.winner ? item.winner?.name : "None"}
                                </td>
                                <td>{dateFormat(item.createdAt)}</td>
                                <td>
                                  {item.status !== "SUCCESS" &&
                                  item.amount === user.withdrawHoldBalance ? (
                                    <button
                                      style={{
                                        backgroundColor: "red",
                                        color: "white",
                                        padding: "5px 10px",
                                        borderRadius: "5px",
                                        border: "none",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        checkfailedpayout(
                                          item.paymentGateway,
                                          item._id,
                                          item.referenceId
                                        )
                                      }
                                    >
                                      Check {item.paymentGateway}
                                    </button>
                                  ) : (
                                    "Checked"
                                  )}
                                </td>
                                <td>
                                  {item.gameType && (
                                    <Link
                                      type="button"
                                      className="btn btn-primary mx-1"
                                      style={{
                                        fontSize: "20px",
                                        borderRadius: "10px",
                                      }}
                                      to={`/view/${item._id}`}
                                    >
                                      <GrFormView />
                                    </Link>
                                  )}
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                  )}

                  {Boolean(challenge) && (
                    <div
                      className="table-responsive"
                      style={{ marginTop: "20px" }}
                    >
                      <table
                        className="table"
                        style={{
                          backgroundColor: "#cedcf5",
                          borderRadius: "10px",
                          overflow: "hidden",
                          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                        }}
                      >
                        <thead
                          style={{
                            backgroundColor: "#090c0f",
                            color: "#c8d8e8",
                            fontWeight: "bold",
                          }}
                        >
                          <tr>
                            <th>#</th>
                            <th>ID</th>
                            <th>Creator</th>
                            <th>Accepter</th>
                            <th>Amount</th>
                            <th>Win/Lose Amt</th>
                            <th>Closing Balance</th>
                            <th>Status</th>
                            <th>Game Type</th>
                            <th>Winner</th>
                            <th>Date</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {challenge.map((game, key) =>
                            game.winner?._id !== user?._id &&
                            game.status === "Win Credited" ? (
                              ""
                            ) : (
                              <tr
                                key={key}
                                style={{
                                  backgroundColor:
                                    key % 2 === 0 ? "#b4d1ed" : "#f1f1f1",
                                }}
                              >
                                <td>{key + 1}</td>
                                <td>{game._id}</td>
                                <td>
                                  {game.createdBy ? (
                                    <Link
                                      className="btn"
                                      to={`/user/view_user/${game.createdBy._id}`}
                                      style={{
                                        backgroundColor: "#e5e7eb",
                                        color: "#1f2937",
                                        fontWeight: "bold",
                                        borderRadius: "20px",
                                        padding: "5px 15px",
                                        textTransform: "capitalize",
                                        fontSize: "14px",
                                      }}
                                    >
                                      {game.createdBy?.name}
                                    </Link>
                                  ) : (
                                    "None"
                                  )}
                                </td>
                                {/* <td>
                                {game.createdBy ? game.createdBy.name : "N/A"}
                              </td> */}
                                <td>
                                  {game.acceptedBy ? (
                                    <Link
                                      className="btn"
                                      to={`/user/view_user/${game.acceptedBy._id}`}
                                      style={{
                                        backgroundColor: "#e5e7eb",
                                        color: "#1f2937",
                                        fontWeight: "bold",
                                        borderRadius: "20px",
                                        padding: "5px 15px",
                                        textTransform: "capitalize",
                                        fontSize: "14px",
                                      }}
                                    >
                                      {game.acceptedBy?.name}
                                    </Link>
                                  ) : (
                                    "None"
                                  )}
                                </td>
                                {/* <td>
                                {game.acceptedBy ? game.acceptedBy.name : "N/A"}
                              </td> */}
                                <td>₹{game.gameAmount ?? 0}</td>
                                <td
                                  style={{
                                    color:
                                      game.winner &&
                                      user._id === game.winner._id &&
                                      game?.winAmount
                                        ? "#3ea113"
                                        : game?.gameAmount &&
                                          game.status === "completed"
                                        ? "#f73b3b"
                                        : game.status === "Hold Credited"
                                        ? "#3ea113"
                                        : game.status === "cancelled"
                                        ? "#f73b3b"
                                        : "#a1aec4",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {game?.winner &&
                                  user._id === game.winner._id &&
                                  game.winAmount
                                    ? `+ ₹${game.winAmount}`
                                    : game?.gameAmount &&
                                      game.status === "completed"
                                    ? `- ₹${game.gameAmount}`
                                    : game.status === "Hold Credited"
                                    ? `+ ₹${game.gameAmount}`
                                    : game.status === "cancelled"
                                    ? `- ₹${game.gameAmount}`
                                    : ""}
                                </td>

                                <td>
                                  {(() => {
                                    if (
                                      game?.status === "cancelled" ||
                                      game.status === "Hold Credited" ||
                                      game?.status === "completed" ||
                                      game?.status === "Win Credited"
                                    ) {
                                      return user._id === game.acceptedBy?._id
                                        ? `₹${
                                            game.Acceptor_closingbalance ?? 0
                                          }`
                                        : `₹${
                                            game.Creator_closingbalance ?? 0
                                          }`;
                                    }
                                    return null;
                                  })()}
                                </td>
                                <td
                                  style={{
                                    color:
                                      game.status === "cancelled" ||
                                      game.status === "completed"
                                        ? "#f73b3b"
                                        : game.status === "drop"
                                        ? "#f79605"
                                        : "#3ea113",
                                    fontWeight: "bold",
                                    fontSize: "13px",
                                  }}
                                >
                                  {game.status === "completed"
                                    ? game.winner?._id === user?._id
                                      ? "completed"
                                      : "Game Lose"
                                    : game.status}
                                </td>
                                <td
                                  style={{
                                    fontSize: "13px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {decodeURIComponent(game.gameType)}
                                </td>
                                <td
                                  style={{
                                    color: !game.winner ? "#a1aec4" : "#3ea113",
                                    fontWeight: "bold",
                                    fontSize: "13px",
                                  }}
                                >
                                  {game.winner
                                    ? game.winner?.name
                                    : game.creatorStatus === "win"
                                    ? game.createdBy?.name
                                    : game.acceptorStatus === "win"
                                    ? game.acceptedBy?.name
                                    : "None"}
                                </td>
                                <td>{dateFormat(game.createdAt)}</td>
                                <td>
                                  <Link
                                    type="button"
                                    className="btn btn-primary mx-1"
                                    style={{
                                      fontSize: "20px",
                                      borderRadius: "10px",
                                    }}
                                    to={`/view/${game._id}`}
                                  >
                                    <GrFormView />
                                  </Link>
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                  )}

                  {Boolean(txn) && (
                    <div
                      className="table-responsive"
                      style={{ marginTop: "20px" }}
                    >
                      <table
                        className="table"
                        style={{
                          backgroundColor: "#cedcf5",
                          borderRadius: "10px",
                          overflow: "hidden",
                          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                        }}
                      >
                        <thead
                          style={{
                            backgroundColor: "#090c0f",
                            color: "#c8d8e8",
                            fontWeight: "bold",
                          }}
                        >
                          <tr>
                            <th>#</th>
                            <th>ID</th>
                            <th>Amount</th>
                            <th>Closing Balance</th>
                            <th>Status</th>
                            <th>Payment Gateway</th>
                            <th>Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {txn.map((data, key) => (
                            <tr
                              key={key}
                              style={{
                                backgroundColor:
                                  key % 2 === 0 ? "#b4d1ed" : "#f1f1f1",
                              }}
                            >
                              <td>{key + 1}</td>
                              <td>{data._id}</td>
                              <td
                                style={{
                                  color:
                                    data.status === "PAID" ||
                                    data.status === "success" ||
                                    data.status === "Credited"
                                      ? "#3ea113"
                                      : data.status === "Pending"
                                      ? "#ff8903"
                                      : "#de1010",
                                  fontWeight: "bold",
                                }}
                              >
                                ₹{data.amount ?? 0}
                              </td>
                              <td>₹{data.closingBalance ?? 0}</td>
                              <td
                                style={{
                                  color:
                                    data.status === "PAID" ||
                                    data.status === "success" ||
                                    data.status === "Credited"
                                      ? "#3ea113"
                                      : data.status === "Pending"
                                      ? "#ff8903"
                                      : "#de1010",
                                  fontWeight: "bold",
                                  fontSize: "13px",
                                }}
                              >
                                {data.status}
                              </td>
                              <td>{data.paymentGateway ?? "None"}</td>
                              <td>{dateFormat(data.createdAt)}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}

                  {Boolean(bonusHis) && (
                    <div
                      className="table-responsive"
                      style={{ marginTop: "20px" }}
                    >
                      <table
                        className="table"
                        style={{
                          backgroundColor: "#cedcf5",
                          borderRadius: "10px",
                          overflow: "hidden",
                          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                        }}
                      >
                        <thead
                          style={{
                            backgroundColor: "#090c0f",
                            color: "#c8d8e8",
                            fontWeight: "bold",
                          }}
                        >
                          <tr>
                            <th>#</th>
                            <th>ID</th>
                            <th>Amount</th>
                            <th>Closing Balance</th>
                            <th>Status</th>
                            <th>Payment Gateway</th>
                            <th>Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {bonusHis.map((data, key) => (
                            <tr
                              key={key}
                              style={{
                                backgroundColor:
                                  key % 2 === 0 ? "#b4d1ed" : "#f1f1f1",
                              }}
                            >
                              <td>{key + 1}</td>
                              <td>{data._id}</td>
                              <td
                                style={{
                                  color: "#3ea113",

                                  fontWeight: "bold",
                                }}
                              >
                                +₹{data.amount ?? 0}
                              </td>
                              <td>₹{data.closingBalance ?? 0}</td>
                              <td
                                style={{
                                  color: "#3ea113",
                                  fontWeight: "bold",
                                  fontSize: "13px",
                                }}
                              >
                                {data.status}
                              </td>
                              <td>{data.paymentGateway ?? "None"}</td>
                              <td>{dateFormat(data.createdAt)}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}

                  {Boolean(penaltyHis) && (
                    <div
                      className="table-responsive"
                      style={{ marginTop: "20px" }}
                    >
                      <table
                        className="table"
                        style={{
                          backgroundColor: "#cedcf5",
                          borderRadius: "10px",
                          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                        }}
                      >
                        <thead
                          style={{
                            backgroundColor: "#090c0f",
                            color: "#c8d8e8",
                            fontWeight: "bold",
                          }}
                        >
                          <tr>
                            <th>#</th>
                            <th>ID</th>
                            <th>Amount</th>
                            <th>Closing Balance</th>
                            <th>Status</th>
                            <th>Payment Gateway</th>
                            <th>Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {penaltyHis.map((data, key) => (
                            <tr
                              key={key}
                              style={{
                                backgroundColor:
                                  key % 2 === 0 ? "#b4d1ed" : "#f1f1f1",
                              }}
                            >
                              <td>{key + 1}</td>
                              <td>{data._id}</td>
                              <td style={{ color: "red", fontWeight: "bold" }}>
                                {" "}
                                -₹{data.amount ?? 0}
                              </td>
                              <td>₹{data.closingBalance ?? 0}</td>
                              <td
                                style={{
                                  color:
                                    data.status === "Penalty by Admin"
                                      ? "red"
                                      : "green",
                                  fontWeight: "bold",
                                  fontSize: "13px",
                                }}
                              >
                                {data.status}
                              </td>
                              <td>{data.paymentGateway ?? "None"}</td>
                              <td>{dateFormat(data.createdAt)}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}

                  {Boolean(txnwith) && (
                    <div
                      className="table-responsive"
                      style={{ marginTop: "20px" }}
                    >
                      <table
                        className="table"
                        style={{
                          backgroundColor: "#cedcf5",
                          borderRadius: "10px",
                          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                        }}
                      >
                        <thead
                          style={{
                            backgroundColor: "#090c0f",
                            color: "#c8d8e8",
                            fontWeight: "bold",
                          }}
                        >
                          <tr>
                            <th>#</th>
                            <th>ID</th>
                            <th>Type</th>
                            <th>Amount</th>
                            <th>Closing Balance</th>
                            <th>Status</th>
                            <th>Action</th>
                            <th>Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {txnwith.map((data, key) => (
                            <tr
                              key={key}
                              style={{
                                backgroundColor:
                                  key % 2 === 0 ? "#b4d1ed" : "#f1f1f1",
                              }}
                            >
                              <td>{key + 1}</td>
                              <td>{data._id}</td>
                              <td>{data.withdrawType ?? "None"}</td>
                              <td
                                style={{
                                  color:
                                    data.status === "FAILED"
                                      ? "#ff0000"
                                      : data.status === "reject"
                                      ? "#aaad11"
                                      : "#3ea113",
                                  fontWeight: "bold",
                                }}
                              >
                                ₹{data.amount ?? 0}
                              </td>
                              <td>₹{data.closingBalance ?? 0}</td>
                              <td
                                style={{
                                  color:
                                    data.status === "FAILED"
                                      ? "#ff0000"
                                      : data.status === "reject"
                                      ? "#aaad11"
                                      : "#3ea113",
                                  fontWeight: "bold",
                                  fontSize: "13px",
                                }}
                              >
                                {data.status === "Refunded"
                                  ? "Withdraw Credited Back to Wallet"
                                  : data.status}
                                {/* {data.status} */}
                              </td>
                              <td>
                                {data.status !== "SUCCESS" &&
                                data.amount === user.withdrawHoldBalance ? (
                                  <button
                                    className="btn btn-danger"
                                    onClick={() =>
                                      checkfailedpayout(
                                        data.paymentGateway,
                                        data._id,
                                        data.referenceId
                                      )
                                    }
                                  >
                                    Check {data.paymentGateway}
                                  </button>
                                ) : (
                                  "Checked"
                                )}
                              </td>
                              <td>{dateFormat(data.createdAt)}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}

                  {Boolean(referral) && (
                    <div
                      className="table-responsive"
                      style={{ marginTop: "20px" }}
                    >
                      <table
                        className="table"
                        style={{
                          backgroundColor: "#cedcf5",
                          borderRadius: "10px",
                          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                        }}
                      >
                        <thead
                          style={{
                            backgroundColor: "#090c0f",
                            color: "#c8d8e8",
                            fontWeight: "bold",
                          }}
                        >
                          <tr>
                            <th>#</th>
                            <th>ID</th>
                            <th>Earned By</th>
                            <th>Amount</th>
                            <th>Closing Balance</th>
                            <th>Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {referral.map((data, key) => (
                            <tr
                              key={key}
                              style={{
                                backgroundColor:
                                  key % 2 === 0 ? "#b4d1ed" : "#f1f1f1",
                              }}
                            >
                              <td>{key + 1}</td>
                              <td>{data._id}</td>
                              <td
                                style={{
                                  color: "#eb8407",
                                  fontWeight: "bold",
                                  fontSize: "15px",
                                  textTransform: "capitalize",
                                }}
                              >
                                {data.earnedFrom?.name ?? "None"}
                              </td>
                              <td
                                style={{
                                  color: "#58a641",
                                  fontWeight: "bold",
                                  fontSize: "15px",
                                }}
                              >
                                +₹{data.amount ?? 0}
                              </td>
                              <td>₹{data.closingBalance ?? 0}</td>
                              <td>{dateFormat(data.createdAt)}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}

                  {Boolean(kyc) && (
                    <div
                      className="table-responsive"
                      style={{ marginTop: "20px" }}
                    >
                      <table
                        className="table"
                        style={{
                          backgroundColor: "#cedcf5",
                          borderRadius: "10px",
                          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                        }}
                      >
                        <thead
                          style={{
                            backgroundColor: "#090c0f",
                            color: "#c8d8e8",
                            fontWeight: "bold",
                          }}
                        >
                          <tr>
                            <th>#</th>
                            <th>ID</th>
                            <th>Doc Name</th>
                            <th>Aadhar No</th>
                            {/* <th>DOB</th> */}
                            <th>Document-Front</th>
                            <th>Document-Back</th>
                            <th>Status</th>
                            <th>Date</th>
                            <th>Accept || Cancel</th>
                          </tr>
                        </thead>
                        <tbody>
                          {kyc.map((item, index) => (
                            <tr
                              key={index}
                              style={{
                                backgroundColor:
                                  index % 2 === 0 ? "#b4d1ed" : "#f1f1f1",
                              }}
                            >
                              <td>{index + 1}</td>
                              <td>{item._id}</td>
                              <td>{item?.name}</td>
                              <td>{item.number}</td>
                              {/* <td>{item.dob ?? "N/A"}</td> */}
                              <td>
                                <div className="img-panel">
                                  <img
                                    className="img"
                                    src={baseUrl + item.front}
                                    alt="kyc front"
                                    style={{
                                      borderRadius: "5px",
                                      width: "4rem",
                                      height: "4rem",
                                    }}
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="img-panel">
                                  <img
                                    className="img"
                                    src={baseUrl + item.back}
                                    alt="kyc back"
                                    style={{
                                      borderRadius: "5px",
                                      width: "4rem",
                                      height: "4rem",
                                    }}
                                  />
                                </div>
                              </td>
                              <td>
                                <span
                                  className={`badge badge-pill ${
                                    item.verified === "verified"
                                      ? "badge-success text-dark"
                                      : item.verified === "pending"
                                      ? "badge-warning text-dark"
                                      : "badge-danger"
                                  }`}
                                >
                                  {item.verified}
                                </span>
                              </td>
                              <td>{dateFormat(item.createdAt)}</td>
                              <td>
                                <button
                                  className="btn btn-success mr-2"
                                  disabled={item.verified === "verified"}
                                  style={{
                                    fontSize: "20px",
                                    borderRadius: "10px",
                                  }}
                                  onClick={() => update(item._id)}
                                >
                                  <FcApprove />
                                </button>
                                <button
                                  className="btn btn-danger"
                                  disabled={item.verified === "reject"}
                                  style={{
                                    fontSize: "20px",
                                    borderRadius: "10px",
                                  }}
                                  onClick={() => deletedata(item._id)}
                                >
                                  <GiCancel />
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
