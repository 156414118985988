import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { GrFormView } from "react-icons/gr";
const $ = require("jquery");
$.Datatable = require("datatables.net");

export default function Completed() {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }

  const [challenge, setchallenge] = useState();
  //use for pagination..
  let [limit, setLimit] = useState(10);

  const setpageLimit = (event) => {
    let key = event.target.value;
    setLimit(key);
  };
  const [pageNumber, setPageNumber] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);

  //react paginate..
  const handlePageClick = async (data) => {
    let currentPage = data.selected + 1;
    setPageNumber(currentPage);
    // scroll to the top
    //window.scrollTo(0, 0)
  };
  const Allchallenge = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .get(
        baseUrl +
          `challange/completed_challange?page=${pageNumber}&_limit=${limit}`,
        { headers }
      )
      .then((res) => {
        setchallenge(res.data.status);
        setNumberOfPages(res.data.totalPages);
        //$('table').dataTable();
      });
  };

  const CancelGame = async (id) => {
    const confirm = window.confirm("are you sure to cancel");

    if (confirm === true) {
      const access_token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${access_token}`,
      };
      axios
        .patch(
          baseUrl + `challange/Cancel/${id}`,
          { Cancelled_by: access_token },
          { headers }
        )
        .then((res) => {
          Allchallenge();
        });
    } else {
      window.alert("sorry try again");
    }
  };
  const dateFormat = (e) => {
    const date = new Date(e);
    return date.toLocaleString("default", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    });
  };

  useEffect(() => {
    Allchallenge();
  }, [pageNumber, limit]);

  if (challenge === undefined) {
    return null;
  }

  return (
    <>
      {/* <h4 className='font-weight-bold my-3'>ALL CHALLANGES</h4> */}
      <div className="row ">
        <div className="col-12 grid-margin">
          <div
            className="card"
            // style={{ borderRadius: "10px", backgroundColor: "#1f2937" }}
          >
            <div
              className="card-body text-light"
              // style={{ backgroundColor: "#0d1b2a", borderRadius: "10px" }}
            >
              <h4 className="card-title text-light">Completed Challanges</h4>
              <select
                className="form-control col-sm-1 bg-light text-dark"
                id="pagelimit"
                name="pagelimit"
                onChange={setpageLimit}
                style={{
                  height: "40px",
                  minWidth: "120px",
                }}
              >
                <option value="10">Set limit</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="500">500</option>
              </select>
              {challenge.length > 0 ? (
                <>
                  <div className="table-responsive mt-3">
                    <table
                      className="table text-light table-hover"
                      style={{ borderRadius: "10px", color: "#fff" }}
                    >
                      <thead>
                        <tr
                        // style={{ backgroundColor: "#14213d" }}
                        >
                          <th>#</th>
                          <th> ID</th>
                          <th> Creator</th>
                          <th> Accepter</th>
                          <th> Amount </th>
                          <th> Status </th>
                          <th> Game Type </th>
                          <th> winner </th>
                          <th>Date</th>
                          <th> Action </th>
                        </tr>
                      </thead>

                      <tbody>
                        {challenge &&
                          challenge.map((game, key) => (
                            <tr
                              key={game._id}
                              style={{ borderBottom: "1px solid #e5e7eb" }}
                            >
                              <td>{key + 1}</td>
                              <td>{game._id}</td>
                              <td>
                                {game.createdBy && (
                                  <Link
                                    className="nameButton badge"
                                    to={`/user/view_user/${game.createdBy._id}`}
                                    // style={{
                                    //   backgroundColor: "#e5e7eb",
                                    //   color: "#1f2937",
                                    //   fontWeight: "bold",
                                    //   borderRadius: "20px",
                                    //   padding: "5px 15px",
                                    //   textTransform: "capitalize",
                                    //   fontSize: "14px",
                                    // }}
                                  >
                                    {game.createdBy.name}
                                  </Link>
                                )}
                              </td>
                              {/* <td>
                            <span className="pl-2 ">
                              {" "}
                              {game.createdBy ? game.createdBy.name : null}
                            </span>
                          </td> */}
                              <td>
                                {game.acceptedBy && (
                                  <Link
                                    className="nameButton badge btn"
                                    to={`/user/view_user/${game.acceptedBy._id}`}
                                    // style={{
                                    //   backgroundColor: "#e5e7eb",
                                    //   color: "#1f2937",
                                    //   fontWeight: "bold",
                                    //   borderRadius: "20px",
                                    //   padding: "5px 15px",
                                    //   textTransform: "capitalize",
                                    //   fontSize: "14px",
                                    // }}
                                  >
                                    {game.acceptedBy.name}
                                  </Link>
                                )}
                              </td>

                              {/* <td>
                            <span className="pl-2">
                              {game.acceptedBy ? game.acceptedBy.name : null}
                            </span>
                          </td> */}
                              <td>₹{game.gameAmount}</td>
                              <td className="text-success font-weight-bold">
                                {game.status}
                              </td>
                              <td
                              // style={{ fontSize: "13px", fontWeight: "bold" }}
                              >
                                {" "}
                                {decodeURIComponent(game.gameType)}
                              </td>
                              <td
                                style={{
                                  color:
                                    !game.winner &&
                                    !game.createdBy &&
                                    !game.acceptedBy
                                      ? "red"
                                      : "green",
                                  fontWeight: "bold",
                                }}
                              >
                                {game.winner
                                  ? game.winner?.name
                                  : (() => {
                                      if (game.creatorStatus === "win") {
                                        return game.createdBy?.name;
                                      } else if (
                                        game.acceptorStatus === "win"
                                      ) {
                                        return game.acceptedBy?.name;
                                      } else {
                                        return "N/A";
                                      }
                                    })()}
                              </td>

                              {/* <td>{game.winner ? game.winner.name : null}</td> */}
                              <td>{dateFormat(game.createdAt)} </td>
                              <td>
                                <Link
                                  type="button"
                                  className="btn btn-primary mx-1"
                                  style={{
                                    fontSize: "20px",
                                    borderRadius: "10px",
                                  }}
                                  to={`/view/${game._id}`}
                                >
                                  <span
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      fontSize: "24px",
                                      color: "white",
                                    }}
                                  >
                                    <GrFormView />
                                  </span>
                                </Link>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>

                  {numberOfPages > 1 && (
                    <div className="mt-4">
                      <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        breakLabel={"..."}
                        pageCount={numberOfPages}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={3}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination justify-content-center"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                        pageLinkStyle={{ color: "#fff" }}
                        activeLinkStyle={{
                          backgroundColor: "#00ff00",
                          borderColor: "#00ff00",
                        }}
                      />
                    </div>
                  )}
                </>
              ) : (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{
                    backgroundColor: "#14213d",
                    padding: "20px",
                    borderRadius: "10px",
                    color: "#fff",
                    marginTop: "15px",
                  }}
                >
                  <h4>No Data Found</h4>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
