import React, { useEffect, useState } from "react";
import axios from "axios";

const Gameterminate = () => {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  const baseUrl =
    nodeMode === "development" ? beckendLocalApiUrl : beckendLiveApiUrl;

  function terminateUpdate() {
    axios
      .put(baseUrl + "updatesettings", {
        websiteStatus: false,
      })
      .then((response) => {
        console.log("Update successful", response);
        fetchData();
      })
      .catch((error) => {
        console.error("Update failed", error);
      });
  }

  function activeUpdate() {
    axios
      .put(baseUrl + "updatesettings", {
        websiteStatus: true,
      })
      .then((response) => {
        console.log("Update successful", response);
        fetchData();
      })
      .catch((error) => {
        console.error("Update failed", error);
      });
  }

  const [WebSitesettings, setWebsiteSettings] = useState("");

  const fetchData = async () => {
    console.log("fetch data");
    const response = await fetch(baseUrl + "settings/data");
    const data = await response.json();
    console.log(data);
    return setWebsiteSettings(data);
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div
        className="w-100 bg-white p-2 shadow h-50 "
        style={{ border: "1px solid #ccc" }}
      >
        <ul
          className="nav nav-pills mb-4 justify-content-between  "
          id="pills-tab"
          role="tablist"
        >
          <li
            className="nav-item"
            role="presentation"
            style={{ border: "1px solid #ccc", borderRadius: "5px" }}
          >
            <button
              className="nav-link active btn btn-success"
              id="pills-home-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-home"
              type="button"
              role="tab"
              aria-controls="pills-home"
              aria-selected="true"
              onClick={activeUpdate}
            >
              Website Active
            </button>
          </li>
          <li
            className="nav-item"
            role="presentation"
            style={{ border: "1px solid #ccc", borderRadius: "5px" }}
          >
            <button
              className="nav-link active btn btn-danger"
              id="pills-profile-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-profile"
              type="button"
              role="tab"
              aria-controls="pills-profile"
              aria-selected="false"
              onClick={terminateUpdate}
            >
              Website Terminate
            </button>
          </li>
        </ul>

        <div
          className="tab-content "
          id="pills-tabContent"
          style={{ marginTop: "70px" }}
        >
          <div
            className={`tab-pane fade show ${
              WebSitesettings?.websiteStatus === true ? "active" : ""
            } `}
            id="pills-home"
            role="tabpanel"
            aria-labelledby="pills-home-tab"
            tabindex="0"
            style={{ color: "green", marginLeft: "60vh" }}
          >
            <span style={{ fontWeight: "bold" }}>
              Website Status :Game is Active right now !
            </span>
          </div>
          <div
            className={`tab-pane fade show ${
              WebSitesettings?.websiteStatus === true ? "" : "active"
            } `}
            id="pills-profile"
            role="tabpanel"
            aria-labelledby="pills-profile-tab"
            tabindex="0"
            style={{ color: "red", marginLeft: "60vh" }}
          >
            <span style={{ fontWeight: "bold" }}>
              Website Status : Game is terminated !
            </span>
            <br />{" "}
            <span style={{ color: "orange", fontSize: "18px" }}>
              {" "}
              Click on Website Active button to Active the website !
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Gameterminate;
