import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation, Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import "../transaction/imageview.css";
const $ = require("jquery");
$.Datatable = require("datatables.net");
export default function RejectKyc() {
  const [data, setData] = useState([]);

  //use for pagination..
  let [limit, setLimit] = useState(10);

  const setpageLimit = (event) => {
    let key = event.target.value;
    setLimit(key);
  };
  const [pageNumber, setPageNumber] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }

  //react paginate..
  const handlePageClick = async (data) => {
    let currentPage = data.selected + 1;
    setPageNumber(currentPage);
  };

  const All = () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .get(
        baseUrl +
          `aadharcard/all/all/reject?page=${pageNumber}&_limit=${limit}`,
        { headers }
      )
      .then((res) => {
        //setData(res.data)
        //$('table').dataTable();
        setData(res.data.admin);
        setNumberOfPages(res.data.totalPages);
        imageViewer();
      });
  };
  const dateFormat = (e) => {
    const date = new Date(e);
    return date.toLocaleString("default", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    });
  };

  useEffect(() => {
    All();
  }, [pageNumber, limit]);

  function imageViewer() {
    let imgs = document.getElementsByClassName("img"),
      out = document.getElementsByClassName("img-out")[0];
    for (let i = 0; i < imgs.length; i++) {
      if (!imgs[i].classList.contains("el")) {
        imgs[i].classList.add("el");
        imgs[i].addEventListener("click", lightImage);
        function lightImage() {
          let container = document.getElementsByClassName("img-panel")[i];
          container.classList.toggle("img-panel__selct");
        }

        imgs[i].addEventListener("click", openImage);
        function openImage() {
          let imgElement = document.createElement("img"),
            imgWrapper = document.createElement("div"),
            imgClose = document.createElement("div"),
            container = document.getElementsByClassName("img-panel")[i];
          container.classList.add("img-panel__selct");
          imgElement.setAttribute("class", "image__selected");
          imgElement.src = imgs[i].src;
          imgWrapper.setAttribute("class", "img-wrapper");
          imgClose.setAttribute("class", "img-close");
          imgWrapper.appendChild(imgElement);
          imgWrapper.appendChild(imgClose);

          setTimeout(function () {
            imgWrapper.classList.add("img-wrapper__initial");
            imgElement.classList.add("img-selected-initial");
          }, 50);

          out.appendChild(imgWrapper);
          imgClose.addEventListener("click", function () {
            container.classList.remove("img-panel__selct");
            out.removeChild(imgWrapper);
          });
        }
      }
    }
  }

  return (
    <>
      {/* <h4 className='font-weight-bold my-3'>ALL CHALLANGES</h4> */}
      <div className="row ">
        <div className="col-12 grid-margin">
          <div
            className="card"
            style={{ borderRadius: "10px", backgroundColor: "#1f2937" }}
          >
            <div
              className="card-body text-light"
              style={{ backgroundColor: "#0d1b2a", borderRadius: "10px" }}
            >
              <div className="img-out"></div>
              <h4 className="card-title text-light"> User Reject KYC</h4>
              <select
                className="form-control col-sm-1 bg-light text-dark"
                id="pagelimit"
                name="pagelimit"
                onChange={setpageLimit}
                // style={{
                //   borderRadius: "5px",
                //   backgroundColor: "#343a40",
                //   color: "#fff",
                //   marginLeft: "150vh",
                // }}
                style={{
                  height: "40px",
                  minWidth: "120px",
                }}
              >
                <option value="10">Set limit</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="500">500</option>
              </select>
              {data?.length > 0 ? (
                <>
                  <div className="table-responsive mt-3">
                    <table
                      className="table text-light table-hover"
                      // style={{ borderRadius: "10px", color: "#fff" }}
                    >
                      <thead>
                        <tr
                        // style={{ backgroundColor: "#14213d" }}
                        >
                          <th>#</th>
                          <th>ID</th>
                          <th>Profile name</th>
                          <th>Doc name</th>
                          <th>Phone</th>
                          <th>Aadhar no</th>
                          {/* <th>dob</th> */}
                          <th>Document-Front</th>
                          <th>Document-Back</th>
                          <th>Status </th>
                          <th>Date </th>
                          {/* <th> Approve </th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {data &&
                          data.map((item, index) => (
                            <tr
                              key={index}
                              // style={{ borderBottom: "1px solid #e5e7eb" }}
                            >
                              <td>{index + 1}</td>
                              <td>{item._id}</td>
                              <td>
                                {item.userId && (
                                  <Link
                                    className="nameButton badge"
                                    to={`/user/view_user/${item.userId._id}`}
                                    // style={{
                                    //   backgroundColor: "#e5e7eb",
                                    //   color: "#1f2937",
                                    //   fontWeight: "bold",
                                    //   borderRadius: "20px",
                                    //   padding: "5px 15px",
                                    //   textTransform: "capitalize",
                                    //   fontSize: "14px",
                                    // }}
                                  >
                                    {item.userId.name}
                                  </Link>
                                )}
                              </td>
                              <td>{item.name}</td>
                              <td>{item.userId.phone}</td>
                              <td>{item.number}</td>
                              {/* <td>{item.dob}</td> */}
                              <td>
                                <div className="img-panel">
                                  <img
                                    className="img"
                                    src={baseUrl + `${item.front}`}
                                    alt="kyc"
                                    style={{
                                      borderRadius: "5px",
                                      width: "4rem",
                                      height: "4rem",
                                    }}
                                    id={`kyc${index}`}
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="img-panel">
                                  <img
                                    className="img"
                                    src={baseUrl + `${item.back}`}
                                    alt="kyc"
                                    style={{
                                      borderRadius: "5px",
                                      width: "4rem",
                                      height: "4rem",
                                    }}
                                    id={`kyc${index}`}
                                  />
                                </div>
                              </td>
                              <td>
                                <span
                                  className={`badge badge-pill ${
                                    item.verified === "verified"
                                      ? "badge-success"
                                      : "badge-danger text-dark"
                                  }`}
                                  // style={{
                                  //   fontSize: "12px",
                                  //   fontWeight: "bold",
                                  // }}
                                >
                                  {item.verified}
                                </span>
                              </td>

                              <td>{dateFormat(item.createdAt)}</td>
                              {/* <td>
                                        <button className="btn btn-success mr-1" >Approve</button>
                                    </td> */}
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>

                  {numberOfPages > 1 && (
                    <div className="mt-4">
                      <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        breakLabel={"..."}
                        pageCount={numberOfPages}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={3}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination justify-content-center"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                        pageLinkStyle={{ color: "#fff" }}
                        activeLinkStyle={{
                          backgroundColor: "#00ff00",
                          borderColor: "#00ff00",
                        }}
                      />
                    </div>
                  )}
                </>
              ) : (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{
                    backgroundColor: "#14213d",
                    padding: "20px",
                    borderRadius: "10px",
                    color: "#fff",
                    marginTop: "15px",
                  }}
                >
                  <h4>No Data Found</h4>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
